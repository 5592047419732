<template>
  <div class="">
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/settings" class="">
            <a>Ustawienia</a>
          </router-link>
        </ul>
      </nav>
    </portal-target>
    <ValidationObserver v-slot="{ handleSubmit, errors }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <h2 class="title"></h2>
              </div>
              <div class='buttons level-right'>
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div class="columns is-variable is-6 is-multiline">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_email">Email</label>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <input class="input is-medium is-lowercase" v-model="user.email" id="f_settings_user_email" type="email" placeholder=""
                  readonly="readonly" value="">
              </div>
            </div>
          </div>
        </div>
        <div :class="errors['firstname'] && errors['firstname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_firstname">Imię</label>
              <span v-if="errors['firstname'] && errors['firstname'].length > 0"
                class="help is-danger">{{ errors['firstname'][0] }}</span>              
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="firstname">
                  <input name="firstname" v-model="user.firstname" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div :class="errors['lastname'] && errors['lastname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_lastname">Nazwisko</label>
              <span v-if="errors['lastname'] && errors['lastname'].length > 0"
                class="help is-danger">{{ errors['lastname'][0] }}</span>                
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="lastname">
                  <input name="lastname" v-model="user.lastname" class="input is-medium" />
                </ValidationProvider> 
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-variable is-6 is-multiline">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_pass">Hasło</label>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <b-input size="is-medium" type="password" v-model="user.password" id="f_settings_user_pass" password-reveal></b-input>
            </div>
          </div>
        </div>
        <div :class="errors['nip'] && errors['nip'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_nip">NIP</label>
              <span v-if="errors['nip'] && errors['nip'].length > 0"
                class="help is-danger">{{ errors['nip'][0] }}</span>                
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="nip">
                  <input name="nip" v-model="user.nip" class="input is-medium" />
                </ValidationProvider> 
              </div>
            </div>
          </div>
        </div>        
        <div :class="errors['phone'] && errors['phone'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_phone">Telefon</label>
              <span v-if="errors['phone'] && errors['phone'].length > 0"
                class="help is-danger">{{ errors['phone'][0] }}</span>                
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="phone">
                  <input name="phone" v-model="user.phone" class="input is-medium" />
                </ValidationProvider> 
              </div>
            </div>
          </div>
        </div>        
        <div :class="errors['address'] && errors['address'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_address">Adres</label>
              <span v-if="errors['address'] && errors['address'].length > 0"
                class="help is-danger">{{ errors['address'][0] }}</span>                
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="address">
                  <input name="address" v-model="user.address" class="input is-medium" />
                </ValidationProvider> 
              </div>
            </div>
          </div>
        </div>        
        <div :class="errors['postcode'] && errors['postcode'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_postcode">Kod pocztowy</label>
              <span v-if="errors['postcode'] && errors['postcode'].length > 0"
                class="help is-danger">{{ errors['postcode'][0] }}</span>                
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="postcode">
                  <input name="postcode" v-model="user.postcode" class="input is-medium" />
                </ValidationProvider> 
              </div>
            </div>
          </div>
        </div>        
        <div :class="errors['city'] && errors['city'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_settings_user_city">Miasto</label>
              <span v-if="errors['city'] && errors['city'].length > 0"
                class="help is-danger">{{ errors['city'][0] }}</span>                
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="city">
                  <input name="city" v-model="user.city" class="input is-medium" />
                </ValidationProvider> 
              </div>
            </div>
          </div>
        </div>        
      </form>   
    </ValidationObserver>

  </div>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {
    ValidationObserver,
    ValidationProvider
  } from 'vee-validate';
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Settings',
    components: {
      FixedHeader,
      ValidationObserver,
      ValidationProvider
    },    
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },     
    created() {

    },    
    beforeDestroy() {
      this.getUser();
    },
    methods: {
      ...mapActions({
        getUser: 'auth/userDetails',
        updateUser: 'auth/userUpdate'
      }),
      onSubmit() {
        this.updateUser(this.user)
      },
    }
  }
</script>