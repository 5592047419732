<template>
<div>
  <section class="section pt">
    <div class="ym-toptitle-box">
      <div id="toptitle" class="ym-toptitle">
        <div class="level">
          <h2 class="title level-left"><router-link to="/login" class="button xbtn is-medium is-transparent"><icon name="x"></icon></router-link> Przypomnienie hasła</h2>
        </div>
      </div>
    </div>
    <div class="columns is-variable is-6 is-multiline">
      <div class="column is-8">
        <ValidationObserver v-slot="{ handleSubmit, errors }">
          <form class="password_remind" @submit.prevent="handleSubmit(onSubmit)">
            <div :class="errors['email'] && errors['email'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
              <div class="column ym-column is-4">
                <div class="ym-label">
                  <label for="email">E-mail</label>
                  <span v-if="errors['email'] && errors['email'].length > 0" class="help is-danger">{{ errors['email'][0] }}</span>
                </div>
              </div>
              <div class="column ym-column is-8">
                <div class="field">
                  <div class="control is-medium">
                    <ValidationProvider rules="required|email" name="email">
                      <input name="email" type="text" v-model="user.email" class="input is-medium" />
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-variable is-6 is-multiline">
                <div class="column ym-column is-4"></div>
                <div class="column ym-column is-8 mab15">
                    <div class="ym-infobox">
                        <p class="label-small">Na podany adres e-mail wyślemy link umożliwiający zmianę hasła.</p>
                    </div>
                </div>
            </div>

              <div class="columns is-variable is-6 is-multiline">
                  <div class="column ym-column is-4">
                      <div class="ym-label">
                          <label for="f_dm_transporternumber"></label>
                          <p></p>
                      </div>
                  </div>
                  <div class="column ym-column is-8">
                      <div class="field">
                          <div class="control is-medium">
                            <button class="button is-medium is-dark is-justify is-fullwidth"><span>Wyślij</span><icon name="arrow-right"></icon></button>
                          </div>
                      </div>
                  </div>
              </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </section>    
</div>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import {mapActions} from 'vuex'

  export default {
    name: 'PasswordRemind',
    components: {
      ValidationObserver,
      ValidationProvider
    },     
    data: function() {
      return {
        user: {
          email: '',
          address: `${window.location.protocol}//${window.location.hostname}/password-set`,        
        }
      }
    },
    methods: {
    ...mapActions({
        passwordRemind: 'auth/passwordRemind'
      }),       
      onSubmit() {
        this.passwordRemind(this.user)
          .then((resp) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: resp.alert[1],
              position: 'is-bottom',
              type: resp.alert[0] === 'negative' ? 'is-danger' : 'is-success'
            })            
          })
      }
    }    
  }
</script>

<style scoped>
  .container {
    margin-top: 5rem;
  }
    .section.pt {
    padding-top: 11rem;
  }
</style>